/* You can add global styles to this file, and also import other style files */
@import "assets/styles/style";


.swal2-container {
    background-color: white;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    width: 100%;
    padding: 17px;
    border-radius: 5px;
    text-align: center;
    position: fixed;
    left: 50%;
    top: 50%;
    // margin-left: -256px;
    // margin-top: -200px;
    overflow: hidden;
    display: none;
    z-index: 2060 !important;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    z-index: 3;
    color: #fff;
    cursor: default;
    background-color: #337ab7;
    border-color: #337ab7;
}
.pagination > li > a, .pagination > li > span {
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;
    color: inherit;
    float: left;
    line-height: 1.42857;
    margin-left: -1px;
    padding: 4px 10px;
    position: relative;
    text-decoration: none;
}
.pagination > li > a, .pagination > li > span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #337ab7;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
}

.ticket-image {
  height: auto;
  width: 100%;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    background-color: #337AB7;
    color: white;
}

.map-tabs.active  > a, .map-tabs.active > a:hover, .map-tabs.active > a:focus {
    background-color: #2E2E2E !important;
    color: white !important;
    border-radius: 0px !important;
}

.map-tabs > a:hover, .map-tabs > a:focus {
    border-radius: 0px !important;
}

.esri-popup__main-container {
    width: 600px !important;
    max-height: 400px !important;
}

select,
select:focus,
textarea,
textarea:focus, 
input[type="text"], 
input[type="password"], 
input[type="datetime"], 
input[type="datetime-local"], 
input[type="date"], 
input[type="month"], 
input[type="time"], 
input[type="week"], 
input[type="number"], 
input[type="email"], 
input[type="url"], 
input[type="search"], 
input[type="tel"], 
input[type="color"] { 
    font-size: 16px !important; 
}

@media (min-width: 768px) {
    select,
    select:focus,
    textarea,
    textarea:focus, 
    input[type="text"], 
    input[type="password"], 
    input[type="datetime"], 
    input[type="datetime-local"], 
    input[type="date"], 
    input[type="month"], 
    input[type="time"], 
    input[type="week"], 
    input[type="number"], 
    input[type="email"], 
    input[type="url"], 
    input[type="search"], 
    input[type="tel"], 
    input[type="color"] { 
        font-size: 14px !important; 
    }
}

.nav>li.active {
    border-color: #1c84c6;
}

.themeBlue {
    background-color: #337ab7 !important;
    border-color: #337ab7 !important;
    margin: 5px;
}

.tooltip {
  pointer-events: none;
}

button[disabled]{
    cursor: not-allowed !important;
    /* filter: alpha(opacity=65) !important; */
    box-shadow: none !important;
    opacity: .65 !important;
}

.swal2-height-auto {
    /* height: auto !important; */
    height: 100% !important;
}

.esri-widget--button {
    color: #1c84c6 !important;
}
//CUSTOM BUTTONS
.btn-orange.btn-outline:hover{
  color: #fff;
}

.btn-orange {
  background-color: #FF6C4D;
  border-color: #FF6C4D;
  color: #FFFFFF;
}

.btn-orange:hover, .btn-orange:focus, .btn-orange:active, .btn-orange.active, .open .dropdown-toggle.btn-orange, .btn-orange:active:focus, .btn-orange:active:hover, .btn-orange.active:hover, .btn-orange.active:focus {
  background-color: darken(#FF6C4D, 3%);
  border-color: darken(#FF6C4D, 3%);
  color: #FFFFFF;
}

.btn-orange:active, .btn-orange.active, .open .dropdown-toggle.btn-orange {
  background-image: none;
}

.btn-orange.disabled, .btn-orange.disabled:hover, .btn-orange.disabled:focus, .btn-orange.disabled:active, .btn-orange.disabled.active, .btn-orange[disabled], .btn-orange[disabled]:hover, .btn-orange[disabled]:focus, .btn-orange[disabled]:active, .btn-orange.active[disabled], fieldset[disabled] .btn-orange, fieldset[disabled] .btn-orange:hover, fieldset[disabled] .btn-orange:focus, fieldset[disabled] .btn-orange:active, fieldset[disabled] .btn-orange.active {
  background-color: lighten(#FF6C4D, 4%);
  border-color: lighten(#FF6C4D, 4%);
}

html, body { height: 100%; }
body { margin: 0; font-family: 'Roboto', sans-serif; }

bs-datepicker-container, bs-daterangepicker-container {
    z-index: 2080;
}

.report-modal {
    width: 600px;
    -webkit-transform: translate(0, 50%) !important;
	-moz-transform: translate(0, 50%) !important;
	transform: translate(0, 50%) !important;
}

html, body { height: 100%; }
body { margin: 0; font-family: 'Roboto', sans-serif; }
